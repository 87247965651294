import { Component, OnInit, Output } from '@angular/core';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { NavigationEnd, Router } from '@angular/router';
import { PatientusRole } from '../../auth/auth.roles';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '../store/sidebar.state';
import { Store } from '@ngrx/store';
import {
    APPOINTMENT_MULTIUSER_REQUESTS,
    APPOINTMENT_OVERVIEW_MULTIUSER_ROUTE,
    APPOINTMENT_OVERVIEW_ROUTE,
    DASHBOARD_ROUTE,
    HELP_ROUTE,
    PAYMENT_ROUTE,
    SETTING_ROUTE,
    TAN_APPOINTMENT_ROUTE,
    WAITINGROOM_MULTIUSER_ROUTE,
    WAITINGROOM_ROUTE
} from '../../../routes';
import { AppointmentRequestService } from '../../appointment-request/providers/appointment-request.service';
import { ProfileService } from '../../profile/providers/profile.service';
import { COMMON_ROUTES, RestrictedFeatureRouteMap } from '../../../entities/institution/institution.model';
import { PaymentInfo } from '../../../entities/payment-info/store/payment-info.model';
import { Observable } from 'rxjs';
import { RouterHelperService } from '../../utils/router-helper/router-helper.service';
import { take } from 'rxjs/operators';
import { version } from '../../utils/version';
import { InstitutionService } from '../../institution-settings/providers/institution.service';
import { FeatureFlagService } from '../../feature-flag/providers/feature-flag.service';
import { FeatureFlag } from '../../feature-flag/feature-flag.types';

export enum SideBarTabs {
    DASHBOARD = 'dashboard',
    WAITINGROOM = 'waitingroom',
    WAITINGROOM_MULTIUSER = 'waitingroom-multiuser',
    TAN_CREATE = 'tan-create',
    APPOINTMENT_OVERVIEW = 'appointment-overview',
    APPOINTMENT_OVERVIEW_MULTIUSER = 'appointment-overview-multiuser',
    APPOINTMENT_MULTIUSER_REQUESTS = 'appointment-multiuser-requests',
    SETTING = 'setting',
    HELP = 'help',
    PAYMENT = 'payment'
}

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: false
})
export class SidebarComponent implements OnInit {

    @Output() version = this.getCurrentVersion();
    public versionUpdatedDate: string;

    public PATIENT: string = PatientusRole.PATIENT;
    public DOCTOR: string = PatientusRole.DOCTOR;
    public remoteVideoVisibility$: Observable<boolean>;
    public hideSideBar$: Observable<boolean>;
    public roles$: Observable<NgxPermissionsObject>;
    public hideTabBasedOnInstitution: boolean;

    public countAppointmentRequests = 0;

    public activeTab;

    public DASHBOARD: SideBarTabs = SideBarTabs.DASHBOARD;
    public WAITINGROOM: SideBarTabs = SideBarTabs.WAITINGROOM;
    public WAITINGROOM_MULTIUSER: SideBarTabs = SideBarTabs.WAITINGROOM_MULTIUSER;
    public TAN_CREATE: SideBarTabs = SideBarTabs.TAN_CREATE;
    public APPOINTMENT_OVERVIEW: SideBarTabs = SideBarTabs.APPOINTMENT_OVERVIEW;
    public APPOINTMENT_OVERVIEW_MULTIUSER: SideBarTabs = SideBarTabs.APPOINTMENT_OVERVIEW_MULTIUSER;
    public APPOINTMENT_MULTIUSER_REQUESTS: SideBarTabs = SideBarTabs.APPOINTMENT_MULTIUSER_REQUESTS;
    public SETTING: SideBarTabs = SideBarTabs.SETTING;
    public HELP: SideBarTabs = SideBarTabs.HELP;
    public PAYMENT: SideBarTabs = SideBarTabs.PAYMENT;
    public currentInstitute;

    public paymentInfo: PaymentInfo;

    public isMUCEnabled: boolean;

    private routeMap: { [elementName: string]: string } = {};

    constructor(private permissionService: NgxPermissionsService,
                private router: Router,
                private routerHelper: RouterHelperService,
                private store: Store<AppState>,
                private appointmentRequestService: AppointmentRequestService,
                private profileService: ProfileService,
                private institutionService: InstitutionService,
                private featureFlagService: FeatureFlagService) {
        this.roles$ = permissionService.permissions$;
        this.remoteVideoVisibility$ = this.store.select(s => s.sidebar.remoteVideoVisible);
        this.hideSideBar$ = this.store.select(h => h.sidebar.hideSideBar);

        this.routeMap[SideBarTabs.DASHBOARD] = DASHBOARD_ROUTE;
        this.routeMap[SideBarTabs.WAITINGROOM] = WAITINGROOM_ROUTE;
        this.routeMap[SideBarTabs.WAITINGROOM_MULTIUSER] = WAITINGROOM_MULTIUSER_ROUTE;
        this.routeMap[SideBarTabs.TAN_CREATE] = TAN_APPOINTMENT_ROUTE;
        this.routeMap[SideBarTabs.APPOINTMENT_OVERVIEW] = APPOINTMENT_OVERVIEW_ROUTE;
        this.routeMap[SideBarTabs.APPOINTMENT_OVERVIEW_MULTIUSER] = APPOINTMENT_OVERVIEW_MULTIUSER_ROUTE;
        this.routeMap[SideBarTabs.APPOINTMENT_MULTIUSER_REQUESTS] = APPOINTMENT_MULTIUSER_REQUESTS;
        this.routeMap[SideBarTabs.SETTING] = SETTING_ROUTE;
        this.routeMap[SideBarTabs.HELP] = HELP_ROUTE;
        this.routeMap[SideBarTabs.PAYMENT] = PAYMENT_ROUTE;
        this.versionUpdatedDate = (<any>window).patientusVersionDate;
        this.isMUCEnabled = this.featureFlagService.isFeatureEnabled(FeatureFlag.MULTI_USER_CALL);
    }

    public getCurrentVersion(): string {
        return version.version;
    }

    ngOnInit() {
        this.profileService.getCurrentProfileDataObserver().pipe(take(1)).subscribe(profile => {
            if (!profile) {
                return;
            }
            this.currentInstitute = profile.institution;
        });

        this.setActiveTab(this.router.url);
        this.appointmentRequestService.getNumberOfOpenAppointmentRequests().subscribe(e => this.countAppointmentRequests = e);
        this.hideTabBasedOnInstitution = this.institutionService.hideTabsBasedOnInstitutionName();
        this.listForPathChanges();
    }

    isActiveTab(tabName: SideBarTabs): boolean {
        return this.activeTab === tabName;
    }

    changeTab(tabName: SideBarTabs): void {
        this.routerHelper.navigate([this.routeMap[tabName]]);
    }

    private listForPathChanges(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.setActiveTab(event.url);
            }
        });
    }

    public isNotRestricted(tabName: SideBarTabs): boolean {
        let routes: string[];

        const isRestrictedInstitute = RestrictedFeatureRouteMap.hasOwnProperty(this.currentInstitute);
        if (isRestrictedInstitute === false) {
            routes = COMMON_ROUTES;
        } else {
            routes = RestrictedFeatureRouteMap[this.currentInstitute];
        }

        const route = this.routeMap[tabName];
        return routes.includes(route);
    }

    private setActiveTab(url: string): void {
        let tab = this.getTabByUrl(url);

        if (tab === undefined) {
            tab = this.checkForChildRoute(url);
        }

        if (this.routeMap[this.activeTab] !== url && !!tab) {
            this.activeTab = <SideBarTabs>tab;
        }
    }

    private getTabByUrl(url: string): string {
        return Object.keys(this.routeMap).find(key => this.routeMap[key] === url);
    }

    private checkForChildRoute(url: string): string {
        const urlSegments = url.split('/');
        urlSegments.pop();
        const parentUrl = urlSegments.join('/');

        return this.getTabByUrl(parentUrl);
    }
}
