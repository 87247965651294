import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, ModalCategory } from '../store/modal.state';
import { ModalCloseAction } from '../store/modal.action';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GeneralMessageBusService, GeneralStateType } from '../../message-bus/providers/general-message-bus.service';

@Component({
    selector: 'app-connection-interrupted-overlay',
    templateUrl: './connection-interrupted-overlay.component.html',
    styleUrls: ['./connection-interrupted-overlay.component.scss'],
    standalone: false
})
export class ConnectionInterruptedOverlayComponent implements OnDestroy {

    connectionInterruptedOverlaySubscription: Subscription = null;
    isOverlayVisible: boolean;

    constructor(private translate: TranslateService,
                private store: Store<AppState>,
                private generalMessageBusService: GeneralMessageBusService
    ) {
        this.connectionInterruptedOverlaySubscription = this.store.select(s => s.modal).subscribe(modalStore => {
                this.isOverlayVisible = (modalStore.isActive && (modalStore.category === ModalCategory.INTERRUPT_INFO));
            }
        );
    }

    backToWaitingroomClicked() {
        this.generalMessageBusService.add(GeneralStateType.STATE_CLOSE_CALL);
        this.store.dispatch(new ModalCloseAction());
    }

    ngOnDestroy(): void {
        if (this.connectionInterruptedOverlaySubscription !== null) {
            this.connectionInterruptedOverlaySubscription.unsubscribe();
        }
    }
}
