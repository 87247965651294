import { Component } from '@angular/core';

@Component({
    selector: 'app-terms-patientus',
    templateUrl: './terms-patientus.component.html',
    styleUrls: ['./terms-patientus.component.scss'],
    standalone: false
})
export class TermsPatientusComponent {

    constructor() {
    }
}
