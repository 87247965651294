import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SETTING_PROFILE } from '../../routes';
import { Subscription } from 'rxjs';

export enum SettingTabs {
    PROFILE = 'profile',
    ACCOUNT = 'account',
}

@Component({
    selector: 'app-setting-page',
    templateUrl: './setting-page.component.html',
    styleUrls: ['./setting-page.component.scss'],
    standalone: false
})
export class SettingPageComponent implements OnDestroy {
    public PROFILE: SettingTabs = SettingTabs.PROFILE;
    public ACCOUNT: SettingTabs = SettingTabs.ACCOUNT;
    public profileLink = SETTING_PROFILE;
    public activeSettingTab = SettingTabs.PROFILE;
    private routerSubscription: Subscription = null;

    constructor(private translate: TranslateService,
                private router: Router) {
        this.listenForPathChanges();
    }

    ngOnDestroy(): void {
        if (this.routerSubscription !== null) {
            this.routerSubscription.unsubscribe();
        }
    }

    private listenForPathChanges(): void {
        this.routerSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.setActiveTab(event.url);
            }
        });
    }

    private setActiveTab(url: string): void {
        this.changeActiveTab(this.mapUrlToTab(url));
    }

    private mapUrlToTab(url: string): SettingTabs {
        switch (url) {
            case '/setting/profile': {
                return SettingTabs.PROFILE;
            }
            default: {
                return SettingTabs.PROFILE;
            }
        }
    }

    public changeActiveTab(tabName: SettingTabs): void {
        this.activeSettingTab = tabName;
    }

    public isActiveTab(tabName: SettingTabs): boolean {
        return this.activeSettingTab === tabName;
    }
}
