import { Component } from '@angular/core';
import { LEGAL_DATA_SECURITY } from '../../../../routes';

@Component({
    selector: 'app-data-process-term-patientus',
    templateUrl: './data-process-term-patientus.component.html',
    styleUrls: ['./data-process-term-patientus.component.scss'],
    standalone: false
})

export class DataProcessTermPatientusComponent {
    dataSecurityRoute = LEGAL_DATA_SECURITY;
}
