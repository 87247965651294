import { TagInputComponent } from 'ngx-chips';
import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ConsultationHour } from '../../../../appointment-type/consultation-hour/consultation-hour.model';
import { Product } from '../../../../appointment-type/product/product.model';
import { TimeListItem, TimePickerComponent } from '../../../../../utils/time-picker/time-picker.component';
import { TimeHelperService } from '../../../../../utils/time-helpers/time-helper.service';
import { Moment } from 'moment';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-form-line',
    templateUrl: './form-line.component.html',
    styleUrls: ['./form-line.component.scss'],
    standalone: false
})
export class FormLineComponent implements OnInit, AfterViewInit {

    @Input() allProducts: Product[];
    @Input() prefillConsultationHour: ConsultationHour;
    @Input() weekDay: number;

    @ViewChild('end') end: TimePickerComponent;
    @ViewChild('products', {static: true}) tagInput: TagInputComponent;

    public selectedProducts: Product[] = [];
    public startTimeObject: TimeListItem = {id: '08:00', name: '08:00', timeOnly: '08:00:00'};
    public endTimeObject: TimeListItem = {id: '08:15', name: '08:15', timeOnly: '08:15:00'};

    constructor(private renderer: Renderer2) {
    }

    ngOnInit() {
        this.prefillTimeFrame(this.prefillConsultationHour);
    }

    ngAfterViewInit() {
        const textInputReference = this.tagInput.inputForm.input as ElementRef;
        const inputElement = textInputReference.nativeElement as UntypedFormControl;
        this.renderer.setAttribute(inputElement, 'maxlength', '0');
        this.renderer.setAttribute(inputElement, 'minlength', '0');
    }

    private prefillTimeFrame(ch: ConsultationHour) {
        if (ch) {
            this.selectedProducts = ch.consultationHourItems.map(obj => this.findProductById(obj.productId));

            this.startTimeObject = {
                id: ch.startTime.local().format('HH:mm'),
                name: ch.startTime.local().format('HH:mm'),
                timeOnly: ch.startTime.local().format('HH:mm:ss')
            };
            this.endTimeObject = {
                id: ch.endTime.local().format('HH:mm'),
                name: ch.endTime.local().format('HH:mm'),
                timeOnly: ch.endTime.local().format('HH:mm:ss')
            };
        } else {
            this.selectedProducts = this.allProducts;
        }
    }

    private findProductById(id): Product {
        return this.allProducts.find(p => p.id.toString() === id.toString());
    }

    public mapTimeToMoment(tli: TimeListItem): Moment {
        return TimeHelperService.timeMoment(tli.timeOnly);
    }

    public setStartTime(timList: TimeListItem) {
        this.startTimeObject = timList;
        if (this.startTimeObject.id >= this.endTimeObject.id) {
            this.end.selectNextEntryOf(this.startTimeObject);
            this.endTimeObject = this.end.getSelectedTime();
        }
    }

    public isFormValid(): boolean {
        return (this.selectedProducts.length > 0);
    }

    public setEndTime(timList: TimeListItem) {
        this.endTimeObject = timList;
    }

    public getConsultationHour(): ConsultationHour {
        const newConsultationHour: ConsultationHour = {
            day: this.weekDay,
            isActive: true,
            startTime: this.mapTimeToMoment(this.startTimeObject),
            endTime: this.mapTimeToMoment(this.endTimeObject),
            consultationHourItems: []
        };

        if (this.prefillConsultationHour) {
            newConsultationHour.id = this.prefillConsultationHour.id;
        }

        for (const product of this.selectedProducts) {
            // for new item Id is 0;
            newConsultationHour.consultationHourItems.push({consultationHourItemId: 0, productId: product.id});
        }

        return newConsultationHour;
    }

    public isProductTooLongForTimespan(product: Product,
                                       startTime: TimeListItem = this.startTimeObject,
                                       endTime: TimeListItem = this.endTimeObject): boolean {
        const timespanDuration = TimeHelperService.getDurationInMinutes(
            this.mapTimeToMoment(startTime), this.mapTimeToMoment(endTime)
        );
        return (product.duration > timespanDuration);
    }

    public inputFormClickHandler(event): void {
        if (!event.target.classList.contains('tagInputFormInput')) {
            const input = event.target.querySelector('.tagInputFormInput');
            if (!!input) {
                input.click();
            }
        }
    }
}
