import { Component, Input } from '@angular/core';
import { BrandingState } from '../../../branding/store/branding.state';

@Component({
    selector: 'app-patients-questions',
    templateUrl: './patients-questions.component.html',
    styleUrls: ['./patients-questions.component.scss'],
    standalone: false
})
export class PatientsQuestionsComponent {
    @Input() brandingSettings: BrandingState;

    constructor() {
    }
}
