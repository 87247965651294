import { Component } from '@angular/core';
import { ScrollService } from '../providers/scroll.service';

@Component({
    selector: 'app-register-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: false
})
export class NavigationComponent {

    constructor(private scroll: ScrollService) {
    }

    scrollTo(stepNumber: string) {
        this.scroll.scrollTo(stepNumber);
    }
}
