import { Component, OnInit } from '@angular/core';
import { ModalCloseAction } from '../../store/modal.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/modal.state';
import { detect } from 'detect-browser';

@Component({
    selector: 'app-update-browser-version',
    templateUrl: './update-browser-version.component.html',
    styleUrls: ['./update-browser-version.component.scss'],
    standalone: false
})
export class UpdateBrowserVersionComponent implements OnInit {

    public browserName;
    public downloadChromeBrowser = 'https://www.google.com/chrome/';

    constructor(private store: Store<AppState>) {
    }

    ngOnInit() {
        const browser = detect();
        this.browserName = browser.name;
    }

    continueAnyway() {
        this.store.dispatch(new ModalCloseAction());
    }

    downloadGoogleChrome() {
        window.open(this.downloadChromeBrowser, '_blank');
    }
}
