import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { Appointment } from '../../store/one-time-appointment/appointment.entity';
import { Profile } from '../../../profile/store/profile.entity';
import { TimeHelperService } from '../../../utils/time-helpers/time-helper.service';
import { AppState } from '../../../waitingroom/store/waitingroom.state';
import { AppointmentInfoService } from '../../providers/appointment-info.service';
import { WAITINGROOM_ROUTE } from '../../../../routes';
import { RouterHelperService } from '../../../utils/router-helper/router-helper.service';
import { Conference } from '../../../conference-v2/store/conference.model';
import { WaitingroomStartVideoConference } from '../../../waitingroom/store/waitingroom.action';
import { AppointmentInfoType } from './appointment-info';

@Component({
    selector: 'app-appointment-info',
    templateUrl: './appointment-info.component.html',
    styleUrls: ['./appointment-info.component.scss'],
    standalone: false
})
export class AppointmentInfoComponent implements OnInit {
    @Input() appointment: Appointment;
    @Input() appointmentInfoType: AppointmentInfoType;

    conference: Conference;
    currentProfileId: number;

    participants$: Observable<Profile[]>;
    patientStatus$: Observable<'online' | 'offline' | 'busy'>;
    patientStatusName$: Observable<'online' | 'offline' | 'im Gerätetest'>;

    hideCopiedSuccessfully = true;
    callStarted = false;

    constructor(private appointmentInfoService: AppointmentInfoService,
                private store: Store<AppState>,
                private router: RouterHelperService) {
    }

    ngOnInit() {
        this.currentProfileId = this.appointmentInfoService.getCurrentProfileId();

        this.participants$ = this.appointmentInfoService.loadParticipants(this.appointment, this.currentProfileId);
        this.patientStatusName$ = this.patientStatus$.pipe(
            map((status) => (status === 'busy' ? 'im Gerätetest' : status))
        );
    }

    public startVideoCall() {
        this.callStarted = true;
        this.store.dispatch(new WaitingroomStartVideoConference(this.conference));
    }

    public isToday(dateString: string): boolean {
        return TimeHelperService.isToday(dateString);
    }

    public isAllowToShowCallNow(): boolean {
        return this.appointmentInfoType === AppointmentInfoType.WAITINGROOM;
    }

    public isAllowShowToGoToWaitingRoom(): boolean {
        return this.appointmentInfoType === AppointmentInfoType.UPCOMING;
    }

    public goToWaitingRoom() {
        this.router.navigate([WAITINGROOM_ROUTE]);
    }

    public copiedSuccessfully(): void {
        this.hideCopiedSuccessfully = false;
        setTimeout(() => this.hideCopiedSuccessfully = true, 2000);
    }

    public getTanLoginLink(tan: string): string {
        return `${window.location.origin}/tan-login?tan=${tan}`;
    }
}
