import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ModalErrorComponent } from './error/error.component';
import { AppState, ModalCategory } from './store/modal.state';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: false
})
export class ModalComponent implements OnInit, OnDestroy {

    private storeSubscription: Subscription = null;

    constructor(private store: Store<AppState>, private modalService: NgbModal) {
    }

    ngOnInit(): void {
        this.storeSubscription = this.store.select('modal').subscribe(state => {
            if (!state.isActive) {
                return;
            }

            if (state.category === ModalCategory.ERROR) {
                const modal = this.modalService.open(ModalErrorComponent);
                modal.componentInstance.errors = state.message;
            }

            if (state.category === ModalCategory.OVERLAY_COMPONENT) {
                this.modalService.open(state.component, {backdrop: 'static', windowClass: state.style});
            }
        });
    }

    ngOnDestroy(): void {
        if (this.storeSubscription !== null) {
            this.storeSubscription.unsubscribe();
        }
    }
}
