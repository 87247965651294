import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../../../appointment-type/product/product.model';
import { ProductService } from '../../../appointment-type/product/providers/product.service';
import { ConsultationHourService } from '../../../appointment-type/consultation-hour/providers/consultation-hour.service';
import { ProfileService } from '../../../../profile/providers/profile.service';
import { Profile } from '../../../../profile/store/profile.entity';
import { environment } from '../../../../../../environments/environment';

@Component({
    selector: 'app-tab-consulation-hours',
    templateUrl: './tab-consulation-hours.component.html',
    styleUrls: ['./tab-consulation-hours.component.scss'],
    standalone: false
})
export class TabConsulationHoursComponent implements OnInit {

    public products$: Observable<Product[]>;
    public createProductFormOpen = false;
    public isAllowToGotoPublicProfile = false;
    public currentProfile: Observable<Profile>;
    public publicProfileUrl: string;

    constructor(
        private productService: ProductService,
        private chService: ConsultationHourService,
        private profileService: ProfileService) {
        this.productService.loadAllProducts().subscribe();
        this.chService.loadAllConsultationHour().subscribe();
        this.products$ = this.productService.getAllProductsFromStore();
        this.currentProfile = this.profileService.getCurrentProfileData();
    }

    ngOnInit() {
        this.chService.isAtleastOneConsultationItemExits().subscribe(hasItems => {
            this.isAllowToGotoPublicProfile = hasItems;
        });
        this.currentProfile.subscribe(response => {
            this.publicProfileUrl = environment.publicProfileEndpoint + response.publicProfileUrl;
        });
    }

    onCreateProduct(newProduct: Product): void {
        const subCreate = this.productService.createProduct(newProduct).subscribe(() => {
            subCreate.unsubscribe();
            // TODO Only on success
            this.createProductFormOpen = false;
        });
    }
}
