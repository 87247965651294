import { Component } from '@angular/core';

@Component({
    selector: 'app-browser-info',
    templateUrl: './browser-info.component.html',
    styleUrls: ['./browser-info.component.scss'],
    standalone: false
})
export class BrowserInfoComponent {

  constructor() { }

}
