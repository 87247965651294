import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-start-survey-overlay',
    templateUrl: './start-survey-overlay.component.html',
    styleUrls: ['./start-survey-overlay.component.scss'],
    standalone: false
})
export class StartSurveyOverlayComponent {

    @Output() openSurvey = new EventEmitter<null>();

    constructor() {
    }

    startSurvey() {
        this.openSurvey.emit();
    }
}
