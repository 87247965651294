import { filter } from 'rxjs/operators';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandingState } from '../../branding/store/branding.state';
import { BrandingService } from '../../branding/providers/branding.service';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.scss'],
    standalone: false
})
export class ImprintComponent {
    public brandingData$: Observable<BrandingState>;

    constructor(private brandingService: BrandingService) {
        this.brandingData$ =
            this.brandingService.getBrandingSettingsFromStore().pipe(filter(e => e !== null && e !== undefined));
    }
}
