import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APPOINTMENT_OVERVIEW_UPCOMING, TAN_APPOINTMENT_CREATE_ROUTE } from '../../../../../routes';
import { RouterHelperService } from '../../../../utils/router-helper/router-helper.service';

@Component({
    selector: 'app-create-tan-failed',
    templateUrl: './create-tan-failed.component.html',
    styleUrls: ['./create-tan-failed.component.scss'],
    standalone: false
})
export class CreateTanFailedComponent {

    constructor(private translate: TranslateService, private router: RouterHelperService) {
    }

    public goToCreateAppointment(): void {
        this.router.navigate([TAN_APPOINTMENT_CREATE_ROUTE]);
    }

    public goAppointmentUpcoming(): void {
        this.router.navigate([APPOINTMENT_OVERVIEW_UPCOMING]);
    }
}
