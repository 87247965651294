import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ConsultationHour } from '../../../appointment-type/consultation-hour/consultation-hour.model';
import { Product } from '../../../appointment-type/product/product.model';
import { ProductService } from '../../../appointment-type/product/providers/product.service';
import { FormLineComponent } from './form-line/form-line.component';
import { ConsultationHourService } from '../../../appointment-type/consultation-hour/providers/consultation-hour.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-consultation-hour-form',
    templateUrl: './consultation-hour-form.component.html',
    styleUrls: ['./consultation-hour-form.component.scss'],
    standalone: false
})
export class ConsultationHourFormComponent implements OnInit, OnDestroy {

    @Input() prefillConsultationHours: Observable<ConsultationHour[]>;
    @Input() weekDay: number;

    @Output() onCancel = new EventEmitter<void>();
    @Output() onDelete = new EventEmitter<void>();
    @Output() onSave = new EventEmitter<void>();

    @ViewChildren(FormLineComponent) formLines: QueryList<FormLineComponent>;

    private timeFrameCount = 0;
    public timeFrameIds: number[] = [];

    public allProducts: Product[];
    public consultationHourList: ConsultationHour[];

    private readonly getAllProductsFromStoreSub: Subscription = null;

    public anErrorOccurred = false;

    constructor(private productService: ProductService,
                private consultationHourService: ConsultationHourService,
                private cdr: ChangeDetectorRef) {
        this.getAllProductsFromStoreSub = this.productService.getAllProductsFromStore().subscribe(list => this.allProducts = list);
    }

    ngOnInit() {
        this.prefillConsultationHours.pipe(take(1)).subscribe((list: ConsultationHour[]) => {
            this.consultationHourList = list;
            list.forEach(ch => this.addTimeFrameItem());
            if (list.length <= 0) {
                this.addTimeFrameItem();
            }
        });
    }

    ngOnDestroy(): void {
        if (this.getAllProductsFromStoreSub !== null) {
            this.getAllProductsFromStoreSub.unsubscribe();
        }
    }

    public removeTimeFrameItem(i: number) {
        this.timeFrameIds.splice(i, 1);
        this.cdr.detectChanges();
    }

    public addTimeFrameItem(): void {
        this.timeFrameIds.push(++this.timeFrameCount);
        this.cdr.detectChanges();
    }

    public areAllFormLinesValid(): boolean {
        if (this.formLines) {
            return this.formLines.map(fl => fl.isFormValid()).reduce((rest, thisOne) => rest && thisOne, true);
        } else {
            return false;
        }
    }

    public onFormSubmit(): void {
        const newConsultationHours = this.formLines.map(fl => fl.getConsultationHour());
        const deletedConsultationHours = this.consultationHourList.filter(oldCh => !newConsultationHours
                .map(ch => ch.id)
                .includes(oldCh.id));

        const newConsultationHoursUpdates$ = newConsultationHours.map(ch => {
            if (ch.id) {
                return this.consultationHourService.updateConsultationHour(ch);
            } else {
                return this.consultationHourService.createConsultationHour(ch);
            }
        });

        const toDeleteConsultationHours$ = deletedConsultationHours.map(
            ch => this.consultationHourService.deleteConsultationHour(ch)
        );

        const updateSub = forkJoin(...newConsultationHoursUpdates$, ...toDeleteConsultationHours$).subscribe((results) => {
            this.onSave.emit();
            this.anErrorOccurred = false;
            return updateSub.unsubscribe();
        }, (error) => {
            this.anErrorOccurred = true;
        });
    }
}
