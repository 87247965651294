import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { PatientusRole } from '../../../auth/auth.roles';
import { TokenService } from '../../../conference-v2/providers/token.service';

@Component({
    selector: 'app-dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
    styleUrls: ['./dashboard-layout.component.scss'],
    standalone: false
})
export class DashboardLayoutComponent implements OnInit {
    roles$: Observable<NgxPermissionsObject>;
    PATIENT: string = PatientusRole.PATIENT;

    constructor(
        private permissionService: NgxPermissionsService
    ) {
    }

    public ngOnInit(): void {
        this.roles$ = this.permissionService.permissions$;
    }
}
