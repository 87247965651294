import { Component } from '@angular/core';

@Component({
    selector: 'app-fixed-black-background',
    templateUrl: './fixed-black-background.component.html',
    styleUrls: ['./fixed-black-background.component.scss'],
    standalone: false
})
export class FixedBlackBackgroundComponent {

    constructor() {
    }
}
