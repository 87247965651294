import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BrandingState } from '../../../branding/store/branding.state';
import { ProfileTypeString } from '../../../profile/store/profile.entity';

@Component({
    selector: 'app-branded-header',
    templateUrl: './branded-header.component.html',
    styleUrls: ['./branded-header.component.scss'],
    standalone: false
})
export class BrandedHeaderComponent {
    @Input() profileType: ProfileTypeString;
    @Input() brandingSettings: BrandingState;
    @Output() helpClicked = new EventEmitter();

    constructor() {
    }

    helpedClickHandler(): void {
        this.helpClicked.emit(true);
    }
}
