import { Component } from '@angular/core';

@Component({
    selector: 'app-init-pre-condition',
    templateUrl: './init-pre-condition.component.html',
    styleUrls: ['./init-pre-condition.component.scss'],
    standalone: false
})
export class InitPreConditionComponent {

    constructor() {
    }

}
