import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, ModalCategory } from '../store/modal.state';
import { ModalOpenSurveyAction } from '../store/modal.action';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GeneralMessageBusService, GeneralStateType } from '../../message-bus/providers/general-message-bus.service';

@Component({
    selector: 'app-connection-stopped-overlay',
    templateUrl: './connection-stopped-overlay.component.html',
    styleUrls: ['./connection-stopped-overlay.component.scss'],
    standalone: false
})
export class ConnectionStoppedOverlayComponent implements OnDestroy {

    isOverlayVisible: boolean;
    connectionStoppedOverlaySubscription: Subscription = null;

    constructor(private translate: TranslateService,
                private store: Store<AppState>,
                private generalMessageBusService: GeneralMessageBusService
    ) {
        this.connectionStoppedOverlaySubscription = this.store.select(s => s.modal).subscribe(modalStore => {
                this.isOverlayVisible = (modalStore.isActive && (modalStore.category === ModalCategory.STOP_INFO));
            }
        );
    }

    backToWaitingroomClicked() {
        this.generalMessageBusService.add(GeneralStateType.STATE_CLOSE_CALL);
        this.store.dispatch(new ModalOpenSurveyAction());
    }

    ngOnDestroy(): void {
        if (this.connectionStoppedOverlaySubscription !== null) {
            this.connectionStoppedOverlaySubscription.unsubscribe();
        }
    }
}
