import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RouterHelperService } from '../../../../utils/router-helper/router-helper.service';
import {
    APPOINTMENT_OVERVIEW_UPCOMING,
    CREATE_MULTIUSER_APPOINTMENT_ROUTE
} from '../../../../../routes';
import { CreateMultiUserSuccessHandlerService } from '../../../providers/create-multi-user-success-handler.service';

@Component({
    selector: 'app-create-tan-appointment-fail',
    templateUrl: './create-tan-appointment-fail.component.html',
    styleUrls: ['./create-tan-appointment-fail.component.scss'],
    standalone: false
})
export class CreateTanAppointmentFailComponent implements OnDestroy {

    constructor(private translate: TranslateService,
                private router: RouterHelperService,
                private createMultiUserSuccessHandlerService: CreateMultiUserSuccessHandlerService) {
    }

    public goToCreateAppointment(): void {
        this.router.navigate([CREATE_MULTIUSER_APPOINTMENT_ROUTE]);
    }

    public goAppointmentUpcoming(): void {
        // FIXME: Change for the appointment multiuser overview
        this.router.navigate([APPOINTMENT_OVERVIEW_UPCOMING]);
    }

    ngOnDestroy() {
        this.createMultiUserSuccessHandlerService.error = null;
    }
}
