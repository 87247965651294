import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/modal.state';
import { ModalCloseAction } from '../store/modal.action';

@Component({
    selector: 'app-modal-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
    standalone: false
})

export class ModalErrorComponent {
    @Input() errors;

    constructor(public activeModal: NgbActiveModal, private store: Store<AppState>) {

    }

    dismissModal() {
        this.activeModal.dismiss('Cross click');
        this.store.dispatch(new ModalCloseAction());
    }

    closeModal() {
        this.activeModal.close('Close click');
        this.store.dispatch(new ModalCloseAction());
    }
}
