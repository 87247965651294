import { Component, Input } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileTypeString } from '../../../profile/store/profile.entity';
import { BrandingService } from "../../../branding/providers/branding.service";
import { BrandingState } from "../../../branding/store/branding.state";

@Component({
    selector: 'app-login-layout',
    templateUrl: './login-layout.component.html',
    styleUrls: ['./login-layout.component.scss'],
    standalone: false
})
export class LoginLayoutComponent {
    @Input() questions: ProfileTypeString;
    public brandingSettings$: Observable<BrandingState>;
    public helpControl$ = new BehaviorSubject(false);

    constructor(private brandingService: BrandingService) {
        this.brandingSettings$ = this.brandingService.getBrandingSettingsFromStore();
    }
}
