import { Component, Input } from '@angular/core';
import { BrandingState } from "../../../branding/store/branding.state";

@Component({
    selector: 'app-doctor-questions',
    templateUrl: './doctor-questions.component.html',
    styleUrls: ['./doctor-questions.component.scss'],
    standalone: false
})
export class DoctorQuestionsComponent {
    @Input() brandingSettings: BrandingState;

    constructor() {
    }
}
