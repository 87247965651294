import { Component } from '@angular/core';
import { CustomTranslateService } from '../../../../providers/translate/services/custom-translate.service';
import { Observable, of } from 'rxjs';
import { Languages } from '../../../../providers/translate/models/languages';

@Component({
    selector: 'app-data-security-helios',
    templateUrl: './data-security-helios.component.html',
    styleUrls: ['./data-security-helios.component.scss'],
    standalone: false
})
export class DataSecurityHeliosComponent {
    public currentLanguage$: Observable<Languages> = of(Languages.DE);

    constructor(private customTranslateService: CustomTranslateService) {
        this.currentLanguage$ = this.customTranslateService.getLangFromStore();
    }
}
