import { GendersEnglish, GendersGerman, GenderType } from '../../../genders';
import { Durations, DurationType } from '../../../durations';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_START_DATE, TimeListItem, TimePickerComponent } from '../../../../utils/time-picker/time-picker.component';
import { Store } from '@ngrx/store';
import { EntityStateAppointment } from '../../../store/one-time-appointment/appointment.entity';
import { CreateTanAppointmentFormBuilder } from './create-tan-appointment.form-builder';
import { AnalyticsService } from '../../../../../providers/analytics/analytics.service';
import { InvalidDirective } from '../../../../../forms/directives/invalid.directive';
import { DatePickerDateCompareService } from '../../../../utils/date-picker/date-picker-date-compare.service';
import { TimeHelperService } from '../../../../utils/time-helpers/time-helper.service';
import { CustomTranslateService } from '../../../../../providers/translate/services/custom-translate.service';
import { CreateMultiTanAppointmentDTO } from '../../../providers/create-multi-user-tan.types';
import {
    createMultiUserAppointmentAction,
} from '../../../store/appointment/multi-user-appointment.action';

@Component({
    selector: 'app-create-tan-appointment',
    templateUrl: './create-tan-appointment.component.html',
    styleUrls: ['./create-tan-appointment.component.scss'],
    standalone: false
})

export class CreateTanAppointmentComponent implements OnInit {
    @ViewChild(TimePickerComponent, {static: false}) tanBeginningTimePicker: TimePickerComponent;
    @ViewChildren(InvalidDirective) invalidDirectives: QueryList<InvalidDirective>;

    public tanCreateForm: UntypedFormGroup;
    public genders: GenderType[] = [];
    public durations: DurationType[] = [];
    public beggingTextAddon: string;
    public currentTimeObject: TimeListItem;
    public numberOfPatients = 0;
    public loading = false;

    constructor(private formBuilder: UntypedFormBuilder,
                private translate: TranslateService,
                private analytics: AnalyticsService,
                private store: Store<EntityStateAppointment>,
                public datePickerDateCompare: DatePickerDateCompareService,
                private customTranslateService: CustomTranslateService) {
    }

    ngOnInit() {
        this.durations = Durations;
        this.tanCreateForm = CreateTanAppointmentFormBuilder.build(this.formBuilder);
        this.addPatient();
        this.setFormDefaults();
        this.assignGenders();
    }

    onSubmit() {
        this.markFormFieldsAsDirty();
        this.checkInvalidFields();
        const formValue = this.tanCreateForm.value;
        const appointmentDate = this.datePickerDateCompare.formatISO(formValue.tanDate);
        const hasValidAppointmentDate = !!appointmentDate && !!formValue.tanBeginning.timeOnly;

        if (this.tanCreateForm.valid && hasValidAppointmentDate) {
            this.loading = true;
            this.analytics.trackNewPageView('/createTanAppointment.html', 'Create TAN Appointment');
            const payload = new CreateMultiTanAppointmentDTO(formValue, appointmentDate);
            this.store.dispatch(createMultiUserAppointmentAction({ payload }));
        } else {
            this.analytics.trackWrongFormInputEvent(this.tanCreateForm);
        }
    }

    public setEndTime(selectedTimeObject: TimeListItem) {
        this.currentTimeObject = selectedTimeObject;
        const startTime = TimeHelperService.getMomentDateTime(DEFAULT_START_DATE + ' ' + this.currentTimeObject.timeOnly);
        const selectedDurationValue = this.tanCreateForm.get('tanDuration').value;
        if (!selectedDurationValue) {
            return;
        }
        const endTime = startTime.add(+selectedDurationValue.id, 'm');
        this.beggingTextAddon = endTime.format('HH:mm');

        this.tanCreateForm.controls['tanBeginning'].setValue(this.currentTimeObject);
    }

    public compareByItem(item1: { id: number }, item2: { id: number }): boolean {
        return item1.id === item2.id;
    }

    public onDurationChange() {
        this.setEndTime(this.currentTimeObject);
    }

    public goToNextInput(event) {
        const target = event.srcElement || event.target;
        if (target.nextElementSibling && target.value.length === 2) {
            target.nextElementSibling.focus();
        }
    }

    public addPatient(): void {
        this.numberOfPatients++;
        const patientId = `patient-${this.numberOfPatients.toString()}`;
        const patient = CreateTanAppointmentFormBuilder.createPatient(this.formBuilder, patientId);
        const patients = this.tanCreateForm.controls.patients as UntypedFormArray;
        patients.push(patient);
    }

    public removePatient(index: number): void {
        const patients = this.tanCreateForm.controls.patients as UntypedFormArray;
        patients.removeAt(index);
    }

    public trackByFn(index: number, el: any): number {
        return el.id;
    }

    private setFormDefaults() {
        const form = this.tanCreateForm.controls;
        form['tanDate'].setValue('');
        this.currentTimeObject = {id: '08:00', name: '08:00', timeOnly: '08:00:00'};
        this.setEndTime({id: '08:00', name: '08:00', timeOnly: '08:00:00'});
        form['tanDuration'].setValue({id: '10', name: '10 min'});
        form['isEbmCheckBox'].setValue(false);
        form['message'].setValue('');
    }

    private assignGendersBasedOnLanguage(language: string): void {
        switch (language) {
            case 'en':
                this.genders = GendersEnglish;
                return;
            default:
                this.genders = GendersGerman;
                return;
        }
    }

    private assignGenders(): void {
        this.customTranslateService.getLangFromStore().subscribe(
            (currentLanguage) => {
                this.assignGendersBasedOnLanguage(currentLanguage);
            }
        );
    }

    private markFormFieldsAsDirty(): void {
        Object.keys(this.tanCreateForm.controls).map(key => {
            this.tanCreateForm.get(key).markAsDirty();
        });
    }

    private checkInvalidFields(): void {
        this.invalidDirectives.map((directive) => {
            directive.triggerTooltip();
        });
    }
}
