import { Component } from '@angular/core';
import { AppointmentRequestService } from '../../../appointment-request/providers/appointment-request.service';
import { AppointmentRequest } from '../../../appointment-request/store/appointment-request.entity';

@Component({
    selector: 'app-request-tab',
    templateUrl: './request-tab.component.html',
    styleUrls: ['./request-tab.component.scss', '../appointment-overview/appointment-overview.component.scss'],
    standalone: false
})
export class RequestTabComponent {

    public appointmentRequestList: AppointmentRequest[] = [];

    constructor(private appointmentRequestService: AppointmentRequestService) {
        this.appointmentRequestService.getAppointmentRequestList().subscribe(list => {
            list.forEach((requestElement) => {
                if (this.appointmentRequestList.indexOf(requestElement) === -1) {
                    this.appointmentRequestList.push(requestElement);
                }
            });
        });
    }
}
