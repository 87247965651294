import { Component } from '@angular/core';
import { AnalyticsService } from '../../providers/analytics/analytics.service';

@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss'],
    standalone: false
})
export class AnalyticsComponent {

    constructor(analytics: AnalyticsService) {
    }
}
