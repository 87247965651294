
import {filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Profile } from '../profile/store/profile.entity';
import { Observable } from 'rxjs';
import { ProfileService } from '../profile/providers/profile.service';
import { BrandingService } from '../branding/providers/branding.service';
import { BrandingState } from '../branding/store/branding.state';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    standalone: false
})
export class HelpComponent implements OnInit {

    public profileData$: Observable<Profile>;
    public brandingData$: Observable<BrandingState>;

    constructor(private profileService: ProfileService, private brandingService: BrandingService) {
        this.profileData$ = this.profileService.getCurrentProfileDataObserver().pipe(filter(e => e !== null && e !== undefined));
    }

    ngOnInit() {
        this.brandingData$ = this.brandingService.getBrandingSettingsFromStore().pipe(filter(e => e !== null && e !== undefined));
    }

}
