import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppState, RegisterVoucherState } from '../store/register.state';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RegisterAddVoucherAction } from '../store/register.action';
import { StepVoucherFormBuilder } from './step-voucher-form.builder';

@Component({
    selector: 'app-step-voucher',
    templateUrl: './step-voucher.component.html',
    styleUrls: ['./step-voucher.component.scss'],
    standalone: false
})
export class StepVoucherComponent {
    voucherForm: UntypedFormGroup;

    constructor(private store: Store<AppState>,
                private translate: TranslateService,
                fb: UntypedFormBuilder) {
        this.voucherForm = StepVoucherFormBuilder.build(fb);
        this.voucherForm.valueChanges.subscribe(this.dispatch.bind(this));
    }

    checkValidation() {
        if (!this.voucherForm.valid) {
            Object.keys(this.voucherForm.controls).forEach(key => {
                this.voucherForm.get(key).markAsTouched();
            });
        }
    }

    dispatch(formvalues) {
        this.checkValidation();
        const voucher: RegisterVoucherState = {
            isValid: this.voucherForm.valid,
            voucherCode: formvalues.voucherCode,
            voucherAgreement: formvalues.voucherAgreement
        };

        this.store.dispatch(new RegisterAddVoucherAction(voucher));
        return formvalues;
    }

    onValidateVoucher(voucherCode) {
        if (voucherCode.length < 0) {
            return false;
        }
        //// @TODO we should create a extra validation action cicle in the next sprint
        // this.store.dispatch(new RegisterValidateVoucherAction(this.voucherForm.value.voucherCode));
    }

    /**
     * If the value of the voucherCode is empty, the checkbox will automatically
     * unchecked and disabled. Should the user enter a value, the html will
     * show and the checkbox will be enabled.
     *
     * @return
     */
    voucherCodeChange() {
        const voucherCodeValue = this.voucherForm.controls.voucherCode.value;

        if (typeof voucherCodeValue !== 'undefined' && voucherCodeValue.length > 0) {
            this.voucherForm.controls.voucherAgreement.enable();
        } else {
            this.voucherForm.controls.voucherAgreement.disable();
            this.voucherForm.controls.voucherAgreement.setValue(false);
        }
    }
}
