import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'app-data-process-term-helios',
    templateUrl: './data-process-term-helios.component.html',
    styleUrls: ['./data-process-term-helios.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DataProcessTermHeliosComponent {
}
