import { Component, Input, OnInit } from '@angular/core';
import { MultiUserAppointment } from '../../../store/appointment/multi-user-appointment.entity';
import { Observable, tap } from 'rxjs';
import { Profile } from '../../../../profile/store/profile.entity';
import { AppointmentInfoService } from '../../../providers/appointment-info.service';
import { Conference } from '../../../../conference-v2/store/conference.model';
import { ProfileSelectService } from '../../../../profile/providers/profile-select.service';
import { VonageConfigurationService } from '../../../../../providers/vonage-configuration/vonage-configuration.service';

@Component({
    selector: 'app-multi-user-appointment-info-for-patients',
    templateUrl: './multi-user-appointment-info-for-patients.component.html',
    styleUrls: ['./multi-user-appointment-info-for-patients.component.scss'],
    standalone: false
})
export class MultiUserAppointmentInfoForPatientsComponent implements OnInit {

    @Input() appointment: MultiUserAppointment;
    currentProfileId: number;
    participants$: Observable<Profile[]>;
    participants: Profile[];
    conference: Conference;
    callStarted = false;
    vonageLoading$: Observable<boolean>;

    constructor(private appointmentInfoService: AppointmentInfoService,
                private profileSelectService: ProfileSelectService,
                private vonageConfig: VonageConfigurationService) {
        this.vonageLoading$ = this.vonageConfig.loading$;
    }

    ngOnInit() {
        this.currentProfileId = this.appointmentInfoService.getCurrentProfileId();
        this.participants$ = this.appointmentInfoService.loadMUCParticipants(this.appointment, this.currentProfileId)
            .pipe(
                tap((participants: Profile[]) => this.participants = participants)
            );
        this.appointmentInfoService.loadConference(this.appointment.conferenceId)
            .subscribe((conference: Conference) => {
                this.conference = conference;
            });
    }

    start() {
        const profile = this.profileSelectService.getCurrentProfile();
        const otherProfiles = this.participants;
        this.vonageConfig.start(this.conference, this.appointment, profile, otherProfiles);
    }

}
