import { Component } from '@angular/core';

@Component({
    selector: 'app-simple-layout',
    templateUrl: './simple-layout.component.html',
    styleUrls: ['./simple-layout.component.scss'],
    standalone: false
})
export class SimpleLayoutComponent {

    constructor() {
    }
}
