import { Component, ElementRef, ViewChild } from '@angular/core';
import {
    MultiUserAppointment,
    MultiUserAppointmentRequestType,
    Pagination
} from '../../../store/appointment/multi-user-appointment.entity';
import { MultiUserAppointmentService } from '../../../providers/multi-user-appointment.service';
import { Observable } from 'rxjs';
import { AppointmentInfoType } from '../../appointment-info/appointment-info';
import { ProfileService } from '../../../../profile/providers/profile.service';

@Component({
    selector: 'app-multi-user-history-tab',
    templateUrl: './multi-user-history-tab.component.html',
    styleUrls: [
        './multi-user-history-tab.component.scss',
        '../../appointment-overview/multi-user-appointment-overview/multi-user-appointment-overview.component.scss'
    ],
    standalone: false
})
export class MultiUserHistoryTabComponent {
    public isInit$: Observable<boolean>;
    public isProfileInit$: Observable<boolean>;
    public historyAppointments$: Observable<MultiUserAppointment[]>;
    public HISTORY_APPOINTMENT_INFO_TYPE = AppointmentInfoType.HISTORY;
    public pagination$: Observable<Pagination>;
    @ViewChild('itemsRef') itemsRef: ElementRef;

    constructor(private appointmentService: MultiUserAppointmentService,
                private profileService: ProfileService) {
        this.pagination$ = this.appointmentService.getPagination$();
        this.appointmentService.getAppointments(MultiUserAppointmentRequestType.HISTORY);
        this.isInit$ = appointmentService.isInit();
        this.isProfileInit$ = this.profileService.isInit$();
        this.historyAppointments$ = appointmentService.getAllMultiUserAppointments$();
    }

    public onPageChange(pageNumber: number) {
        const position = this.itemsRef.nativeElement.offsetTop;
        this.appointmentService.getAppointments(
            MultiUserAppointmentRequestType.HISTORY,
            {pageNumber: pageNumber.toString()},
            position
        );
    }
}
