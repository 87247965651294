import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-cancellation-policy-agaplesion',
    templateUrl: './cancellation-policy-agaplesion.component.html',
    styleUrls: ['./cancellation-policy-agaplesion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CancellationPolicyAgaplesionComponent {
}
