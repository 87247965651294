import { RegisterAddTariffAction } from '../store/register.action';
import { AppState, RegisterTariffState } from '../store/register.state';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Component } from '@angular/core';
import { ScrollService } from '../providers/scroll.service';
import { Tariff, Tariffs } from './model/tariff';
import { AnalyticsService } from '../../../providers/analytics/analytics.service';
import { NextStepFrom } from '../navigation/navigation.types';

@Component({
    selector: 'app-step-tariff',
    templateUrl: './step-tariff.component.html',
    styleUrls: ['./step-tariff.component.scss'],
    standalone: false
})
export class StepTariffComponent {

    public tariffs: Tariff[];

    constructor(private store: Store<AppState>,
                private translate: TranslateService,
                private scroll: ScrollService,
                private analytics: AnalyticsService) {
        this.tariffs = Tariffs;
    }

    onSelectTariff(tariffId: number) {
        // TODO: this should actually get the tariff by the id in the object, not by its index
        const state = new RegisterTariffState(true, this.tariffs[tariffId - 1]);
        this.store.dispatch(new RegisterAddTariffAction(state));
        this.scroll.scrollToStep(NextStepFrom.TARIFF);
        this.analytics.trackNewPageView('/enterPersonalData.html', 'Enter Personal Data');
    }
}
