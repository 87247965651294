import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from "ngx-clipboard";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { RouterHelperService } from '../../../../utils/router-helper/router-helper.service';
import {
    CreateMultiUserSuccessHandlerService,
    TanItem
} from '../../../providers/create-multi-user-success-handler.service';
import {
    APPOINTMENT_OVERVIEW_MULTIUSER_UPCOMING,
    CREATE_MULTIUSER_APPOINTMENT_ROUTE
} from '../../../../../routes';

@Component({
    selector: 'app-create-appointment-tan-success',
    templateUrl: './create-tan-appointment-success.component.html',
    styleUrls: ['./create-tan-appointment-success.component.scss'],
    standalone: false
})
export class CreateTanAppointmentSuccessComponent implements OnInit {
    public isEmailSent: string;
    public items: TanItem[];
    public copiedItemsFromObj: string[] = [];

    constructor(private translate: TranslateService,
                private router: RouterHelperService,
                private route: ActivatedRoute,
                private createMultiUserSuccessHandlerService: CreateMultiUserSuccessHandlerService,
                private clipboardService: ClipboardService) {
        this.route.queryParams.subscribe(params => this.isEmailSent = params['emailsent']);
    }

    ngOnInit(): void {
        this.items = Array.from(this.createMultiUserSuccessHandlerService.items);
    }

    public goToCreateAppointment(): void {
        this.router.navigate([CREATE_MULTIUSER_APPOINTMENT_ROUTE]);
    }

    public goAppointmentUpcoming(): void {
        this.router.navigate([APPOINTMENT_OVERVIEW_MULTIUSER_UPCOMING]);
    }

    public copyLink(tan: string): string {
        return `${window.location.origin}/tan-login?tan=${tan}`;
    }

    public copyAllNamesAndTans(): void {
        let index = 0;
        Object.keys(this.items).forEach(key => {
            let objectToBeDisplayed = this.items[key];
            this.copiedItemsFromObj[index] = objectToBeDisplayed.userFullName + ' ' + objectToBeDisplayed.tan;
            index++;
        });
        this.clipboardService.copy(this.copiedItemsFromObj.join('\n'));
    }

    toggleTooltip(tooltip: NgbTooltip): void {
        tooltip.open();
        setTimeout(() => {
            tooltip.close();
        }, 2000);
    }
}
