import { filter, map } from 'rxjs/operators';
import { LEGAL_DATA_SECURITY, WAITINGROOM_MULTIUSER_ROUTE } from '../../../routes';
import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthStateProvider } from '../../auth/auth-state.provider';
import { Store } from '@ngrx/store';
import { AppState } from '../../../providers/store/app.state';
import { Observable, Subscription } from 'rxjs';
import { BrandingService } from '../../branding/providers/branding.service';
import { Profile, ProfileType } from '../../profile/store/profile.entity';
import { selectCurrentProfile } from '../../profile/store/profile.selector';
import { ProfileAcceptDataProcessAction } from '../../profile/store/profile.action';
import { RouterHelperService } from '../../utils/router-helper/router-helper.service';

@Component({
    selector: 'app-data-process-term',
    templateUrl: './data-process-term.component.html',
    styleUrls: ['./data-process-term.component.scss'],
    standalone: false
})

export class DataProcessTermComponent implements OnDestroy {
    public profileData: Profile;
    public isAllowToShowTerm: boolean;
    public backgroundImg$: Observable<{ backgroundImage: string }>;
    public dataSecurityLink = window.location.origin + LEGAL_DATA_SECURITY;
    private subscription: Subscription;

    constructor(
        private translate: TranslateService,
        private authProvider: AuthStateProvider,
        private router: RouterHelperService,
        private store: Store<AppState>,
        private brandingService: BrandingService) {

        this.subscription = this.store.select(selectCurrentProfile).pipe(
            filter(e => e !== null && e !== undefined))
            .subscribe(profile => {
                if (profile.dataProcessTerm === true || profile.type !== ProfileType.PATIENT) {
                    this.router.navigate([WAITINGROOM_MULTIUSER_ROUTE]);
                }
                this.profileData = profile;
                this.isAllowToShowTerm = !this.profileData?.dataProcessTerm && this.profileData?.type === ProfileType.PATIENT;
            });

        this.backgroundImg$ = this.brandingService.getBrandingSettingsFromStore().pipe(
            map(settings => {
                const backgroundImg = settings.bgImage_patientLogin || '/assets/img/general/bg-login.jpg';
                return {backgroundImage: backgroundImg};
            }));
    }

    public logout() {
        this.authProvider.logOut();
    }

    public acceptDataProcess() {
        this.store.dispatch(new ProfileAcceptDataProcessAction(this.profileData));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
