import { map, filter } from 'rxjs/operators';
import { Component } from '@angular/core';
import { AppointmentService } from '../../providers/appointment.service';
import { Observable } from 'rxjs';
import { Appointment } from '../../store/one-time-appointment/appointment.entity';
import { AppointmentInfoType } from '../appointment-info/appointment-info';

@Component({
    selector: 'app-upcoming-tab',
    templateUrl: './upcoming-tab.component.html',
    styleUrls: ['./upcoming-tab.component.scss', '../appointment-overview/appointment-overview.component.scss'],
    standalone: false
})
export class UpcomingTabComponent {
    public isInit$: Observable<boolean>;
    public upcomingAppointments$: Observable<Appointment[]>;
    public upcomingAppointmentInfoType = AppointmentInfoType.UPCOMING;

    constructor(private appointmentService: AppointmentService) {
        this.isInit$ = appointmentService.isInit();
        this.upcomingAppointments$ = appointmentService
            .getAllAppointmentsObserver().pipe(
                filter(a => a !== null),
                map(appointmentList => appointmentList.filter(a => this.appointmentService.isUpcomingAppointment(a))),
                map(appointmentList => appointmentList.sort(this.appointmentService.compareByAppointmentDate)),);
    }
}
