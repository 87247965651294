import { Component, OnInit } from '@angular/core';
import { ModalCloseAction } from '../../store/modal.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/modal.state';
import { detect } from 'detect-browser';

@Component({
    selector: 'app-recommend-chrome-browser',
    templateUrl: './recommend-chrome-browser.component.html',
    styleUrls: ['./recommend-chrome-browser.component.scss'],
    standalone: false
})
export class RecommendChromeBrowserComponent implements OnInit {

    public browserOs;
    public downloadChromeBrowser = 'https://www.google.com/chrome/';

    constructor(private store: Store<AppState>) {
    }

    ngOnInit() {
        const browser = detect();
        this.browserOs = browser.os;
    }

    continueAnyway() {
        this.store.dispatch(new ModalCloseAction());
    }

    downloadNewestGoogleChrome() {
        window.open(this.downloadChromeBrowser, '_blank');
    }
}
