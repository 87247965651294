import { LoginSuccessAction } from '../../store/login/login.action';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/login/login.state';
import { Router, ActivatedRoute } from '@angular/router';
import { LOGIN_ROUTE } from '../../../../routes';

@Component({
    selector: 'app-extern-login',
    templateUrl: './extern-login.component.html',
    styleUrls: ['./extern-login.component.scss'],
    standalone: false
})
export class ExternLoginComponent implements OnInit {
    constructor(private store: Store<AppState>, private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['token'] !== undefined && params['token'] !== null) {
                this.doLogIn(params['token']);
            } else {
                this.router.navigate([LOGIN_ROUTE]);
            }
        });
    }

    private doLogIn(token: string) {
        this.store.dispatch(new LoginSuccessAction(token));
    }
}

