import { Component, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AppointmentInfoType } from '../../appointment-info/appointment-info';
import { MultiUserAppointmentService } from '../../../providers/multi-user-appointment.service';
import { ProfileService } from '../../../../profile/providers/profile.service';
import {
    MultiUserAppointment,
    MultiUserAppointmentRequestType,
    Pagination
} from '../../../store/appointment/multi-user-appointment.entity';

@Component({
    selector: 'app-multi-user-upcoming-tab',
    templateUrl: './multi-user-upcoming-tab.component.html',
    styleUrls: [
        './multi-user-upcoming-tab.component.scss',
        '../../appointment-overview/multi-user-appointment-overview/multi-user-appointment-overview.component.scss'
    ],
    standalone: false
})
export class MultiUserUpcomingTabComponent {
    public isInit$: Observable<boolean>;
    public upcomingAppointments$: Observable<MultiUserAppointment[]>;
    public upcomingAppointmentInfoType = AppointmentInfoType.UPCOMING;
    public pagination$: Observable<Pagination>;
    public isProfileInit$: Observable<boolean>;
    @ViewChild('itemsRef') itemsRef: ElementRef;

    constructor(private appointmentService: MultiUserAppointmentService,
                private profileService: ProfileService) {
        this.appointmentService.getAppointments(MultiUserAppointmentRequestType.UPCOMING);
        this.isInit$ = appointmentService.isInit();
        this.upcomingAppointments$ = appointmentService.getAllMultiUserAppointments$();
        this.pagination$ = this.appointmentService.getPagination$();
        this.isProfileInit$ = this.profileService.isInit$();
    }

    public onPageChange(pageNumber: number) {
        const position = this.itemsRef.nativeElement.offsetTop;
        this.appointmentService.getAppointments(
            MultiUserAppointmentRequestType.UPCOMING,
            {pageNumber: pageNumber.toString()},
            position
        );
    }
}
