import { Component } from '@angular/core';
import { ModalCloseAction } from '../../store/modal.action';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/modal.state';

@Component({
    selector: 'app-safari-not-supported',
    templateUrl: './safari-not-supported.component.html',
    styleUrls: ['./safari-not-supported.component.scss'],
    standalone: false
})
export class SafariNotSupportedComponent {

    constructor(private store: Store<AppState>) {
    }

    continueAnyway() {
        this.store.dispatch(new ModalCloseAction());
    }
}
