import { map, take } from 'rxjs/operators';
import { Component, Input } from '@angular/core';
import { Product, ProductPriceItem, ProductType } from '../../../appointment-type/product/product.model';
import { ProductService } from '../../../appointment-type/product/providers/product.service';
import { ConsultationHourService } from '../../../appointment-type/consultation-hour/providers/consultation-hour.service';
import { zip } from 'rxjs';

@Component({
    selector: 'app-product-list-item',
    templateUrl: './product-list-item.component.html',
    styleUrls: ['./product-list-item.component.scss'],
    standalone: false
})
export class ProductListItemComponent {

    @Input() product: Product;

    getsModified = false;
    showConfirmDelete = false;
    showConfirmDeleteLastProduct = false;

    constructor(private productService: ProductService,
        private consultationHourService: ConsultationHourService) {
    }

    public getShortType(type: ProductType): string {
        switch (type) {
            case ProductType.NOT_FREE:
                return 'GOÄ';
            case ProductType.FREE:
                return 'EBM';
            default:
                return 'Termin';
        }
    }

    public getLineItemSum(priceItem: ProductPriceItem): number {
        return priceItem.paymentValue * priceItem.multiplicator;
    }

    public getTotalSum(priceList: any[]): number {
        return priceList.reduce((last, current) => last + this.getLineItemSum(current), 0);
    }

    public deleteProduct(): void {
        const consultationHourListLength$ = this.consultationHourService.getAllConsultationHoursFromStore().pipe(
            map(ch => ch.length),
            take(1)
        );
        const productListLength$ = this.productService.getProductCountFromStore().pipe(take(1));

        zip(
            consultationHourListLength$,
            productListLength$,
            (chCount: number, pCount: number) => ({chCount, pCount}))
            .subscribe(zipCount => {
                if (zipCount.pCount === 1 && zipCount.chCount > 0) {
                    this.showConfirmDeleteLastProduct = true;
                } else {
                    this.showConfirmDelete = true;
                }
            });
    }

    public deleteProductConfirmed(): void {
        const subDelete = this.productService.deleteProduct(this.product).subscribe(() => {
            subDelete.unsubscribe();
            const subReload = this.productService.loadAllProducts().subscribe(() => {
                subReload.unsubscribe();
            });

            const subCH = this.consultationHourService.loadAllConsultationHour().subscribe(() => {
                subCH.unsubscribe();
                this.showConfirmDelete = false;
            });
        });
    }

    public updateProduct(product: Product): void {
        const subUpdate = this.productService.updateProduct(product).subscribe(() => {
            subUpdate.unsubscribe();
        });
    }
}
